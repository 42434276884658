<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.authentication.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;" v-if="selectedAuthType">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
        <v-select
          v-model="selectedAuthType"
          :items="authenticationTypes"
          :label="$t('dtouch.workspace.authentication.type', locale)"
          item-text="name"
          item-value="id"
          outlined
          hide-details
        />
        </v-col>
      </v-row>
      <v-row v-if="selectedAuthType === 'noray'">
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="norayURL"
            :label="$t('dtouch.workspace.norayURL', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="norayHotelID"
            :label="$t('dtouch.workspace.norayHotelID', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row v-if="selectedAuthType === 'char'">
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="charURL"
            :label="$t('dtouch.workspace.charURL', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row v-if="selectedAuthType === 'dfiles'">
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="dfileWorkspace"
            :label="$t('dtouch.workspace.dfileWorkspace', locale)"
            hide-details
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'AuthenticationForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    selectedAuthType: null,
    norayURL: null,
    norayHotelID: null,
    charURL: null,
    dfileWorkspace: null,
    authenticationTypes: [],
  }),
  watch: {
    selectedAuthType () {
      this.handleChangeAuthData()
    },
    norayURL () {
      this.handleChangeAuthData()
    },
    norayHotelID () {
      this.handleChangeAuthData()
    },
    charURL () {
      this.handleChangeAuthData()
    },
    dfileWorkspace () {
      this.handleChangeAuthData()
    },
  },
  methods: {
    getAuthData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/auth`)
        .then(response => {
          if (response) {
            this.selectedAuthType = response.AuthType ? response.AuthType : 'none'
            this.norayURL = response.NorayURL
            this.norayHotelID = response.NorayHotelID
            this.charURL = response.CharURL
            this.dfileWorkspace = response.DfileWorkspace
          }
        })
    },
    getAuthTypes () {
      api.getAllWithoutLimit('dtouch', 'v1/system/constants/auth-types')
        .then(response => {
          this.authenticationTypes = response
        })
    },
    handleExpand () {
      if(!this.selectedAuthType) {
        this.getAuthTypes()
        if (this.workspaceID) this.getAuthData()
      }
    },
    handleChangeAuthData () {
      this.onChangeData({
        authType: this.selectedAuthType,
        norayURL: this.norayURL,
        norayHotelID: this.norayHotelID,
        charURL: this.charURL,
        dfileWorkspace: this.dfileWorkspace,
      })
    }
  },
}
</script>

